import React from "react"
import { Link } from "gatsby"
import { Text, Stack } from "@chakra-ui/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Sunny Tools" />
    <Stack spacing={8}>
      <Text color="white">
        Useful tools. Mostly to manage my content on{" "}
        <a href="https://sunnysingh.io/" style={{ color: "white" }}>
          sunnysingh.io
        </a>
        .
      </Text>
      <Text color="white">
        <Link to="/quote/" style={{ color: "white" }}>
          Social Quote Generator
        </Link>
      </Text>
    </Stack>
  </Layout>
)

export default IndexPage
